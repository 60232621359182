.loading-box {
    position: fixed;
    background: #dddddd8c;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 100000;
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}